import MODAL_MODE from '../../../pages/data/common/constants';
import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import actions from './actions';
import { CertificateGroup } from '../../../pages/data/organization/tracked-items/types';

export interface CertificateGroupsModalInitialState {
  modalMode: ModalMode;
  certificateGroups: CertificateGroup[];
  filterName: string;
  defaultName: string;
  selectedRelation: string;
  defaultCertificateIds: string[];
  defaultCertificateGroupIds: string[];
  currentCertificateGroupId: string;
}

const initialState: CertificateGroupsModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  certificateGroups: [],
  filterName: null,
  defaultName: null,
  selectedRelation: null,
  defaultCertificateIds: [],
  defaultCertificateGroupIds: [],
  currentCertificateGroupId: null,
};

export default function reducers(state: CertificateGroupsModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_CERTIFICATE_GROUPS_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_CERTIFICATE_GROUPS_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_CERTIFICATE_GROUPS:
    case actions.SET_FILTER_NAME:
      return { ...state, ...action.payload };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
